@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.input-div {
	display: flex;
    align-items: center;
}

.inline-button {
    background: none;
	border: none;
	cursor: pointer;
}

.inline-span {
    cursor: pointer;
	display: inline-block;
	overflow:hidden;
    overflow-wrap: break-word;
    white-space: normal;

}


