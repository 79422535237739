@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

#js-main-content {
	outline: none;
	margin: 0 auto;
	

}
.container {
	margin-bottom: 30px;
}