@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

$space: 0.5em;

.footer-wrapper {
	// Polaris only supports sticky footer
	// https://polaris.a2z.com/components/awsui-app-layout/
	position: fixed;
	position: -webkit-sticky;
	bottom: 0;
	width: 100%;
	z-index: 2001; /* Polaris sets popover to 2000 */
}

.lars-footer {
	display: flex;
	flex-wrap: wrap;
	bottom: 0;
	padding: 1rem 0;
	background-color: #232F3E;
	color: awsui.$color-text-heading-secondary;
	justify-content: center;
	align-items: center;
}

.lars-footer-link {
	text-align: center !important;
	color: #fff !important;
	&:hover{ 
		text-decoration: none !important;
		color: awsui.$color-background-button-primary-default !important;
		cursor: pointer;
		
	}
}

.text-align-center {
	text-align: center !important;
	color: #fff !important;
}

.separator {
	border-right: 2px solid awsui.$color-text-heading-secondary;
	padding: 0 $space 0 0;
	margin: 0 $space 0 0;
	height: 1.4rem; /* Polaris sets font-size to 1.4rem */
}
